import { useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';

import addressApi from '~/api/AddressApi';
import { addToast } from '~/reducers/toastsSlice';
import { selectMainClient } from '~/reducers/mainClientSlice';

import { ApiAddress } from '~/api/types';
import {
    REACT_QUERY_KEY,
    UseFetchMainClientAddressReactQueryOptions
} from './types';

export const useFetchMainClientAddress = () => {
    const dispatch = useDispatch();
    const { t } = useTranslation('error');
    const mainClient = useSelector(selectMainClient);
    const mainClientAddressId = mainClient?.address;

    const fetchAddress = async (addressId?: string) => {
        const {
            data: { data }
        } = await addressApi.get(addressId);
        return data;
    };

    const onError = useCallback(() => {
        dispatch(
            addToast({
                message: t('clientAddressError'),
                variant: 'error'
            })
        );
    }, [dispatch, t]);

    const reactQueryOptions: UseFetchMainClientAddressReactQueryOptions = {
        enabled: Boolean(mainClientAddressId),
        retry: false,
        onError
    };

    return useQuery<ApiAddress, Error>(
        [REACT_QUERY_KEY, mainClientAddressId],
        () => fetchAddress(mainClientAddressId),
        reactQueryOptions
    );
};
