import { isEqual } from 'lodash';
import { AddTaskTypes } from '~/api/types/ApiTask';

const constants = {
    defaultMainClientName: 'client',
    datadog: {
        APP_ID: '50018478-5bce-4d40-adb9-47f307a32bd5',
        URL: 'datadoghq.com',
        SERVICE: 'interstellar',
        defaultPrivacyLevel: 'mask-user-input',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100
    },
    socketReserved: {
        CONNECT: 'connect',
        CONNECT_ERROR: 'connect_error',
        ERROR: 'error'
    },
    toastVariant: {
        ERROR: 'error',
        SUCCESS: 'success'
    },
    socketCustom: {
        JOIN_ROOMS: 'interstellar-room-join',
        LEAVE_ROOMS: 'interstellar-room-leave',
        MESSAGE: 'message',
        INTERSTELLAR_SOLUTION: 'interstellar-solution',
        UNPLANNED_TASKS: 'web-unplanned-tasks',
        CEP_RESULTS: 'cep-results',
        CUSTOMER_SEARCH: 'customer-search',
        CUSTOMER_SEARCH_ERROR: 'customer-search-error',
        DIRECT_ROUTE_IMPACT: 'direct-route-impact',
        COMMAND_NOT_FOUND: 'command-not-found',
        DRIVERS: 'drivers',
        METRICS: 'metrics',
        PAIRING_EXECUTED: 'pairing-executed',
        ON_DEMAND_TASK_ADDED: 'on-demand-task-added',
        ON_DEMAND_TASK_ADDED_BULK: 'on-demand-task-added-bulk',
        ON_DEMAND_TASK_REMOVED: 'on-demand-task-removed',
        UPLOAD: 'upload',
        END_ADJUSTMENT: 'end-adjustment',
        TASKS_INDEXED: 'tasks-indexed',
        ROUTE_PLAN_EDIT_SUGGESTION: 'route-plan-edit-suggestion'
    },
    socketErrors: {
        JWT_EXPIRED: 'jwt expired'
    },
    entityStates: {
        UNPLANNED: 'unplanned',
        PLANNED: 'planned'
    },
    taskStatus: {
        UNASSIGNED: 0,
        DISPATCHED: 1,
        IN_PROGRESS: 2,
        COMPLETED: 3,
        CANCELLED: 4,
        PLANNED: 5
    },
    inventoryItemStatus: {
        UNCONFIRMED: 'unconfirmed',
        CONFIRMED: 'confirmed',
        CANCELLED: 'cancelled',
        PARTIAL: 'partial',
        TRY_LATER: 'try-later'
    },
    taskTypes: {
        PICKUP: 'pickup',
        DELIVERY: 'delivery',
        PICKUP_LOCATION: 'pickupLocation',
        DELIVERY_LOCATION: 'deliveryLocation',
        PICKUP_INVENTORY: 'pickupInventory',
        DELIVERY_INVENTORY: 'deliveryInventory',
        PICKUP_WINDOW: 'pickupWindow',
        DELIVERY_WINDOW: 'deliveryWindow',
        DEPOT: 'depot'
    },
    mapMarkerModes: {
        STOPS_CLUSTERS: 'stops-clusters',
        ROUTES: 'routes'
    } as const,
    mapDrawingModes: {
        POLYGON: 'polygon'
    },
    mapMarkerLabels: {
        PARKING: 'P'
    },
    mapRouteModes: {
        EMPTY: 'empty',
        PLAN: 'plan',
        DISPATCHED: 'dispatched',
        COMPLETED: 'completed'
    },
    mapChildEvents: {
        CLUSTER_MOUSEENTER: 'cluster-marker-mouseenter',
        CLUSTER_MOUSELEAVE: 'cluster-marker-mouseleave',
        CLUSTER_DRAGSTART: 'cluster-marker-dragstart',
        CLUSTER_CLICK: 'cluster-marker-click',
        CLUSTER_MOUSEUP: 'cluster-marker-mouseup',
        CHANGE_MARKER_MODE: 'change-marker-mode',
        ROUTE_MOUSEENTER: 'route-marker-mouseenter',
        ROUTE_MOUSELEAVE: 'route-marker-mouseleave',
        POPUP_MOUSEENTER: 'popup-mouseenter',
        POPUP_MOUSELEAVE: 'popup-mouseleave',
        STOP_MOUSEENTER: 'stop-marker-mouseenter',
        STOP_DRAGSTART: 'stop-marker-dragstart',
        STOP_MOUSEUP: 'stop-marker-mouseup'
    },
    mapEvents: {
        POLYGON_COMPLETE: 'polygoncomplete',
        BOUNDS_CHANGED: 'bounds_changed'
    },
    mapLibraries: {
        DRAWING: 'drawing',
        GEOMETRY: 'geometry',
        PLACES: 'places'
    },
    mapOptionSettings: {
        MAP_MODE_DEFAULT: 'default',
        MAP_MODE_SIMPLE: 'simple',
        MAP_MODE_STATIC: 'static',
        GESTURE_HANDLING_AUTO: 'auto',
        GESTURE_HANDLING_OFF: 'none',
        DEFAULT_GOOGLE_MAP_KEY: 'AIzaSyA8LK2FU5VJ1ugpcfQHyIiDLkUQ270RIoc',
        DEFAULT_ZOOM: 14,
        DEFAULT_CENTER: { lat: 0, lng: 0 },
        DEFAULT_BOUNDS: [],
        MAX_ZOOM: 22,
        MIN_ZOOM: null, // uses map min zoom
        SELECTED_DRAWER_POINT_OFFSET: 0.03,
        SELECTED_DRAWER_CENTER_PAN: {
            x: -100, // half the width of 1 open route drawer
            y: -30 // half the height of navbar and actionbar
        },
        DEFAULT_CLUSTER_RADIUS: 200,
        BOUNDS_PADDING: 20
    },
    url: {
        ROOT: '/',
        ADVANCED_CONFIGURATION: '/advanced-configuration',
        AURORA_AI: '/aurora-ai',
        COST_CONFIGURATION: '/cost-configuration',
        CUSTOMER_DETAILS: '/customer-management/customer-details',
        CUSTOMER_MANAGEMENT: '/customer-management',
        DOWNLOADS: '/downloads',
        DRIVER_BOOKING: '/driver-booking',
        DRIVER_MANAGEMENT_DATE_AGNOSTIC: '/driver-vehicle-management',
        DRIVER_MANAGEMENT: '/driver-management',
        HOME: '/home',
        LOGIN: '/login',
        LOGOUT: '/logout',
        MAP: '/map',
        NOTIFICATION_MANAGEMENT: '/notification-management',
        OPTIMIZATION_CONFIGURATION: '/optimization-configuration',
        REPORTS: '/reports',
        SETTINGS: '/settings',
        TASK_MANAGEMENT: '/task-management',
        UNAUTHORIZED: '/401',
        USERS_AND_PERMISSIONS: '/users-and-permissions',
        WORKFLOW: '/workflow',
        ZONE_MANAGEMENT: '/zone-management',
        WORKFLOWS: '/workflows'
    },
    driverPhase: {
        Initial: 'Initial', // no data is available
        DataGathered: 'DataGathered', // estimated count available
        InProgress: 'InProgress', // confirmed < estimated
        CompletedNotMarked: 'CompletedNotMarked', // confirmed === estimated but not yet clicked
        Marked: 'Marked' // marked
    },
    taskPhase: {
        Initial: 'Initial', // no data is present
        DataGathered: 'DataGathered', // received count is present
        Marked: 'Marked' // marked
    },
    routePhase: {
        Initial: 'Initial', // not all previous steps are marked
        CanBeClicked: 'CanBeClicked', // both driver and task are marked
        Scheduling: 'Scheduling', // scheduler is running
        Marked: 'Marked', // marked
        InitialNoDriverAccess: 'InitialNoDriverAccess',
        CanBeClickedNoPlanAccess: 'CanBeClickedNoPlanAccess',
        InitialNoDriverOrPlanAccess: 'InitialNoDriverOrPlanAccess'
    },
    routeVehicleTypes: {
        noVehicle: 'no_vehicle'
    },
    bookingNotifications: {
        COMPONENT_NAME: 'booking',
        TYPE: 'push_notification'
    },
    bookingStatus: {
        PENDING: 'pending',
        CONFIRMED: 'confirmed',
        DECLINED: 'declined',
        CANCELED: 'canceled',
        WITHDRAWN: 'withdrawn'
    },
    userPermissionFeatures: {
        DRIVER_BOOKING: 'driver_booking',
        DRIVER_MANAGEMENT: 'driver_management',
        PLANNING: 'planning',
        USER_MANAGEMENT: 'user_management',
        TASK_MANAGEMENT: 'task_management',
        MOBILE_MANAGER: 'mobile_manager',
        MOBILE_MERCHANDISER: 'mobile_merchandiser'
    },
    userPermissionRoles: {
        EDITOR: 'editor',
        NO_ACCESS: 'no_access',
        VIEWER: 'viewer'
    },
    timings: {
        SET_CONTENT: 300,
        TEXT_DEBOUNCE: 300,
        MARKER_MOVE_THROTTLE: 50,
        MAP_CHANGE: 0,
        MAP_ZOOM_ANIMATION_END: 1000, // ensures map rendering finished on larger operations
        MESSAGE_CHANGE: 7000,
        GET_WEB_INTERVAL: 60000,
        ROUTE_CARD_CLICK: 1000,
        PROCESS_INDICATOR: 500,
        COURIER_SCHEDULER_INTERVAL: 2000,
        SESSION_REFRESH_INTERVAL: 420000,
        IGNORE_CEP_RESULT: 5000,
        IGNORE_DUPLICATE_FETCH: 1000,
        MANUAL_RTA_REFRESH_TIMEOUT: 5000,
        TASKS_INDEXED_INTERVAL: 10000,
        FETCH_INTERVAL: 1000
    },
    productTypes: {
        SCHEDULER: 'Scheduler',
        SOLAR: 'Solar',
        INTERSTELLAR: 'Interstellar'
    },
    localStorageKeys: {
        MCW_APP_INIT: 'mcwAppInit',
        ENABLE_FEATURE_FLAGS: 'enableFeatureFlags'
    },
    sessionStorageKeys: {
        EXPORTED_ROUTES: 'exportedRoutes',
        LOGIN_PARAMS: 'loginParams'
    },
    featureFlags: {
        development: {
            ALLOW_DISPATCHING_FUTURE_DATES: 'allowDispatchingFutureDates',
            ALLOW_EXTERNAL_TASK_TYPES: 'allowExternalTaskTypes'
        }
    },
    workflowEvents: {
        DRIVER_BOOKING_COMPLETED: 'driver_booking_completed',
        DRIVER_BOOKING_UNCOMPLETED: 'driver_booking_uncompleted',
        TASK_VERIFICATION_COMPLETED: 'task_verification_completed',
        TASK_VERIFICATION_UNCOMPLETED: 'task_verification_uncompleted',
        OPTIMIZATION_COMPLETED: 'optimization_completed',
        OPTIMIZATION_UNCOMPLETED: 'optimization_uncompleted',
        DRIVER_BOOKING_REMINDER_NOTIFICATION_SENT:
            'driver_booking_reminder_notification_sent'
    },
    vehicleTypes: {
        SIDE_LOADER: 'truck_sideload',
        END_LOADER: 'truck_endload'
    },
    violationsDefaultTitles: {
        EVERY_ROUTE_ASSIGNED: 'Violations.EveryRouteIsAssignedToADriver',
        EVERY_DRIVER_ASSIGNED: 'Violations.EveryDriverIsAssignedToARoute',
        TIME_WINDOWS: 'Violations.TimeWindows',
        ORDERS_PER_VEHICLE_TYPE: 'Violations.OrdersPerVehicleType',
        VEHICLE_CAPACITY: 'Violations.VehicleCapacity',
        ROUTE_DURATION: 'Violations.RouteDuration',
        PACKAGES_PER_ROUTE: 'Violations.PackagesPerRoute',
        DRIVER_SHIFT_TIMES: 'Violations.DriverShiftTimes',
        WEIGHT_PER_VEHICLE: 'Violations.WeightPerVehicle'
    },
    violationsViolatedTitles: {
        EVERY_ROUTE_ASSIGNED: 'Violations.RoutesWithNoDrivers',
        EVERY_DRIVER_ASSIGNED: 'Violations.DriversWithNoRoutes',
        TIME_WINDOWS: 'Violations.TimeWindowIsViolated',
        ORDERS_PER_VEHICLE_TYPE: 'Violations.VehicleTypeDoesNotMatchOrder',
        VEHICLE_CAPACITY: 'Violations.VehicleIsOverloadedVolume',
        ROUTE_DURATION: 'Violations.RouteExceedsMaxDuration',
        PACKAGES_PER_ROUTE: 'Violations.RouteExceedsMaxPackages',
        DRIVER_SHIFT_TIMES: 'Violations.DriverShiftTimesViolated',
        WEIGHT_PER_VEHICLE: 'Violations.VehicleIsOverloadedWeight'
    },
    violations: {
        DANGER: 'danger',
        WARNING: 'warning',
        ROUTE_VIOLATION: 'editImpact:Violations.RouteViolation',
        ROUTE_STOP_VIOLATION: 'editImpact:Violations.RouteStopViolation',
        DRIVER_VIOLATION: 'editImpact:Violations.DriverViolation'
    },
    sortOptionKeys: {
        LENGTH: 'sortLength',
        DURATION: 'sortDuration',
        STOP_COUNT: 'sortStopCount',
        STOP_REMAINING_COUNT: 'sortStopRemainingCount',
        UNIT_COUNT: 'sortUnitCount',
        ROUTE_NAME: 'sortRouteName',
        DRIVER_NAME: 'sortDriverName',
        NUMBER_OF_GAPS: 'sortNumberOfGaps'
    },
    sortOrder: {
        ASC: 'asc',
        DESC: 'desc'
    },
    // TODO: Remove report template constants [Sabby: 08/02/2021]
    reportTemplate: {
        EXPORT: 'intelcomRoutePlan',
        DOWNLOAD: 'routePlanDefault'
    },
    schedulerKeys: {
        schedulerProgressDone: 'done',
        closeAllRoutes: 'close',
        uncloseAllRoutes: 'unclose',
        lockRoutes: 'lockRoutes',
        schedulerProgressError: 'error'
    },
    environments: {
        PROD: 'prod',
        UAT: 'uat',
        DEMO: 'demo',
        STAGE: 'stage',
        SANDBOX: 'sandbox',
        DEV: 'dev'
    },
    statusCodes: {
        CONFLICT: 409,
        UNPROCESSABLE: 422
    },
    requestHeaders: {
        // TO BE DEPRECATED AND REPLACED WITH WISE_CLIENT_IDS
        WISE_CLIENT_ID: 'wise-client-id',
        WISE_CLIENT_IDS: 'wise-client-ids'
    },
    priorityCodes: {
        HIGH: 1,
        MEDIUM: 2,
        LOW: 3
    },
    filterOptionKeys: {
        HAS_GROUP: 'filterHasGroup',
        WITH_ISSUES: 'filterWithIssues',
        WITH_LATE_STOP: 'filterWithLateStop',
        WITH_STOP_RISK: 'filterWithStopRisk',
        WITH_FAILED_DELIVERY: 'filterWithFailedDelivery',
        WITH_CANCELED_DELIVERY: 'filterWithCanceledDelivery',
        ACTIVE_ROUTES: 'filterActiveRoutes',
        INACTIVE_ROUTES: 'filterInactiveRoutes',
        PICKUP_TASKS: 'pickupTasks',
        DELIVERY_TASKS: 'deliveryTasks'
    },
    liveStopMarkerStatus: {
        default: 'liveStopMarkerDefault',
        priority: 'liveStopMarkerPriority',
        canceled: 'liveStopMarkerCanceled'
    },
    onDemandDispatch: {
        API_RESPONSE_LIMIT: 150,
        CONTROL_DISPATCH_LIMIT: 5
    },
    apiErrorMessages: {
        // manual pair validation
        HAVE_DIFF_VEHICLE_IDS: 'Both tasks must have the same vehicleEid',
        // time windows validation
        PICKUP_DELIVERY_TIME_WINDOWS:
            'The start pickup time is after the end of delivery time.'
    },
    cepErrorCodes: {
        CLIENT_PREFERENCES_CONSTRAINT: 2
    },
    mapsDrawingStyles: {
        SOLID: {
            strokeColor: '#000',
            strokeWeight: 4,
            strokeOpacity: 1
        },
        DOTTED: {
            strokeOpacity: 0,
            icons: [
                {
                    icon: {
                        path: 'M 0,0 0,0',
                        strokeOpacity: 1,
                        scale: 4
                    },
                    offset: '0',
                    repeat: '7px'
                }
            ]
        },
        DASHED: {
            strokeOpacity: 0,
            icons: [
                {
                    icon: {
                        path: 'M 0,-.5 0,.5',
                        strokeOpacity: 1,
                        scale: 4
                    },
                    offset: '0',
                    repeat: '14px'
                }
            ]
        }
    },
    mapsDirectionIconPath:
        'M -1.5,2.25 L 0,0 L 1.5,2.25 L 1.5,0 L 0,-2.25 L -1.5,0 L -1.5,2.25 L -1.8375,1.8925',
    reactQueryClientOptions: {
        default: {
            queries: {
                // retries on error responses
                retry: 1,
                // delay before retry (milliseconds)
                retryDelay: 5000,
                // refetch when user refocuses on browser window
                refetchOnWindowFocus: false,
                // how long to keep successful data fresh before refetching (milliseconds)
                staleTime: 0
            }
        },
        test: {
            queries: {
                // disables the cache, allow initial `data` to revert to `undefined`
                cacheTime: 0,
                // retries on error responses
                retry: false
            }
        }
    },
    reactQueryKeys: {
        ADDRESS_BY_ID: 'address_by_id',
        COMPARTMENTS: 'compartments',
        CUSTOM_COST_MATRIX: 'custom_cost_matrix',
        CUSTOM_MAPPING: 'custom_mapping',
        TASKS_ASSIGN: 'tasks/assign',
        TASKS_BULK_SUGGEST: 'tasks/bulk_suggest',
        TASKS: 'tasks',
        TASKS_METRICS: 'tasks/metrics',
        TASKS_PAIR_OPTIMAL: 'tasks/pair_optimal',
        TASKS_PAIR_MANUAL: 'tasks/pair_manual',
        TASKS_PAIR_DEPOT: 'tasks/pair_depot',
        USER_GROUPS: 'user_groups',
        ZONES: 'zones',
        ZONE_VEHICLE_ASSOCIATION: 'zones_vehicle_association',
        ZONES_CUSTOM_COST_CONFIG: 'zones_custom_cost_config',
        DRIVER_REPORT: 'driver_report'
    },
    stopsControl: {
        views: {
            RESEQUENCE: 'resequenceView',
            REASSIGN: 'reassignView',
            MAIN: 'mainView'
        }
    },
    processIndicator: {
        REASSIGN_STOP: 'reassign_stop',
        REASSIGN_LIVE_STOP: 'reassign_live_stop',
        UNASSIGN_STOP: 'unassign_stop',
        RESEQUENCE_STOP: 'resequence_stop',
        REASSOCIATE_DRIVER_VEHICLE: 'reassociate_driver_vehicle',
        APPEND_ROUTE: 'append_route',
        CHANGE_DRIVER: 'change_driver',
        OPTIMIZE_ROUTE: 'optimize_route',
        TASK_UPLOAD: 'task_upload',
        UNDO: 'undo',
        DRIVER_UPLOAD: 'driver_upload',
        ZONE_UPLOAD: 'zone_upload',
        DELETE_BREAK: 'delete_break',
        ADD_BREAK: 'add_break',
        RESEQUENCE_BREAK: 'resequence_break',
        EDIT_BREAK_DURATION: 'edit_break_duration',
        UPDATE_BREAK_RULES: 'update_break_rules'
    },
    externalProcessIndicators: {
        task_upload: true
    },
    taskUpload: { TYPE: 'task_upload' },
    driverUpload: { TYPE: 'driver_upload' },
    uploadStatus: {
        COMPLETE: 'Processing Complete',
        ACCEPTED: 'Schedule Accepted - Processing',
        FAILED: 'Upload failed',
        STATS: 'Schedule Stats',
        TASK_CREATED: 'Task Created',
        DRIVER_VEHICLE_PROCESSED: 'Driver/Vehicle Processed'
    },
    zoneMap: {
        DEFAULT_ZOOM: 12
    },
    customerMap: {
        DEFAULT_ZOOM: 16,
        SLIDER_MAX: '200',
        SLIDER_MIN: '10',
        childEvents: {
            DRAG_START: 'drag_start',
            MOUSE_ENTER: 'mouse_enter',
            MOUSE_UP: 'mouse_up'
        }
    },
    customerSearch: {
        searchTermLength: 3
    },
    mapView: {
        type: {
            DEFAULT: 'roadmap',
            SATELLITE: 'satellite'
        }
    },
    zoneMapPolygon: {
        defaultStyle: {
            fillColor: '#5EA2FF',
            fillOpacity: 0.3,
            strokeColor: '#3282FF',
            strokeOpacity: 1,
            strokeWeight: 3,
            clickable: false
        },
        fadedFillColor: '#BFD8FB'
    },
    addNewDriver: {
        FIRSTNAME: 'firstname',
        LASTNAME: 'lastname',
        COLOR: 'color',
        PASSWORD: 'password',
        loginTypes: {
            USERNAME: 'username',
            TELEPHONE: 'telephone'
        },
        DEFAULT_SHIFTS: {
            0: [{ start: '08:00', end: '18:00' }],
            1: [{ start: '08:00', end: '18:00' }],
            2: [{ start: '08:00', end: '18:00' }],
            3: [{ start: '08:00', end: '18:00' }],
            4: [{ start: '08:00', end: '18:00' }],
            5: [{ start: '08:00', end: '18:00' }],
            6: [{ start: '08:00', end: '18:00' }]
        },
        emptyDriverFields: {
            firstname: '',
            lastname: '',
            username: '',
            telephone: '',
            password: ''
        },
        password: {
            minimumLength: 8
        },
        defaultErrorState: {
            firstname: false,
            lastname: false,
            username: false,
            telephone: false,
            showErrorMessage: false
        }
    },
    common: {
        on: 'on',
        off: 'off',
        maxClientRouteIdsPerStopsRequest: 5
    },
    addVehicleForm: {
        names: {
            rta: 'addNewVehicleRta',
            name: 'addNewVehicleName',
            licensePlate: 'addNewVehicleLicensePlate',
            type: 'addNewVehicleType',
            weight: 'addNewVehicleWeight',
            volume: 'addNewVehicleVolume',
            startDepot: 'addNewVehicleStartDepot',
            endDepot: 'addNewVehicleEndDepot',
            vehicleProfile: 'addNewVehicleProfile'
        }
    },
    routeChangeOptions: {
        addRoute: 'add',
        replaceRoute: 'replace'
    },
    editHistoryAction: {
        trim: 'trim',
        resequence: 'resequence',
        reassign: 'reassign',
        augment: 'augment',
        append: 'append',
        optimize: 'optimize',
        changeDriver: 'change_driver',
        changeDriverAndVehicle: 'change_driver_and_vehicle',
        breaks: 'breaks'
    },
    notifications: {
        notificationStatus: {
            dispatched: 'dispatched',
            xMinutesAway: 'away',
            atRisk: 'risk',
            completed: 'completed'
        },
        mapping: {
            dispatched: [
                {
                    title: 'dispatched',
                    clientSettingName: 'notifyOnCreateAssignment',
                    notificationSentName: 'notificationSent'
                },
                {
                    title: 'away',
                    clientSettingName: 'notifyBasedOnTime',
                    notificationSentName: 'ETANotificationSent'
                },
                {
                    title: 'risk',
                    clientSettingName: 'notifyOnDelayChange',
                    notificationSentName: 'delayedNotificationSent'
                }
            ],
            inProgress: [
                {
                    title: 'arrived',
                    clientSettingName: 'notifyOnStartAssignment',
                    notificationSentName: 'startNotificationSent'
                }
            ],
            completed: [
                {
                    title: 'completed',
                    clientSettingName: 'notifyOnCompleteAssignment',
                    notificationSentName: 'completeNotificationSent'
                }
            ]
        },
        notificationStatusLifecycle: {
            sent: 'sent',
            disabled: 'disabled',
            scheduled: 'scheduled',
            enabled: 'enabled'
        },
        settings: {
            type: {
                default: 'default',
                custom: 'custom'
            }
        }
    },
    routeSummaryMetrics: {
        VOLUME: 'volume',
        STOPS: 'stops',
        DISTANCE: 'distance',
        DURATION: 'duration',
        WEIGHT: 'weight'
    },
    tripSpecificSummaryMetrics: {
        PICKUP_VOLUME: 'pickup_volume',
        PICKUP_WEIGHT: 'pickup_weight',
        DELIVERY_VOLUME: 'delivery_volume',
        DELIVERY_WEIGHT: 'delivery_weight',
        TWO_PART_TASK_WEIGHT: 'two_part_task_weight',
        TWO_PART_TASK_VOLUME: 'two_part_task_volume'
    },
    taskPriority: {
        SLIDER_STEP: '1',
        SLIDER_MIN: '1',
        SLIDER_MAX: '3'
    },
    depots: {
        START_NUMBER: 'start',
        END_NUMBER: 'end',
        START_DEPOT: 'Start Depot',
        END_DEPOT: 'End Depot',
        START_DEPOT_STOP_NUMBER: 0,
        END_DEPOT_STOP_NUMBER: 999,
        searchLimitRange: 0.5
    },
    screens: {
        settings: {
            advancedConfiguration: {
                subPages: {
                    driverBreakSettings: 'driver-break-settings',
                    multiCompartmentSettings: 'multi-compartment-settings',
                    taskDetailsSettings: 'task-details-settings',
                    defaultTaskFields: 'default-task-fields'
                }
            }
        }
    },
    radioOption: {
        YES: 'Yes',
        NO: 'No'
    },
    locales: {
        EN: 'en',
        enUS: 'en-US',
        enGB: 'en-GB',
        FR: 'fr',
        JA: 'ja'
    },
    allSupportedTaskExtents:
        'deliveryLocation,pickupLocation,sizeByCompartment.compartmentType',
    externalTaskType: {
        MAX_LENGTH: 64
    },
    none: 'None',
    other: 'Other',
    externalTaskTypePredefinedData: {
        delivery: ['Bobtail', 'Empty delivery', 'Chassis'],
        pickup: ['Bobtail', 'Empty pickup', 'Chassis'],
        twoPart: [
            'Bobtail - Bobtail',
            'Empty pickup - Empty delivery',
            'Chassis - Chassis'
        ]
    } as Record<AddTaskTypes, string[]>,
    translationKeys: {
        externalTaskTypes: {
            operationalStop: {
                weighScales: 'externalTaskTypes.operationalStop.weighScales',
                borderCrossings:
                    'externalTaskTypes.operationalStop.borderCrossings',
                breakdownPoints:
                    'externalTaskTypes.operationalStop.breakdownPoints',
                turnaroundPoints:
                    'externalTaskTypes.operationalStop.turnaroundPoints',
                stagingOrDropYards:
                    'externalTaskTypes.operationalStop.stagingOrDropYards',
                washBays: 'externalTaskTypes.operationalStop.washBays',
                repairBay: 'externalTaskTypes.operationalStop.repairBay',
                other: 'externalTaskTypes.operationalStop.other'
            }
        }
    },
    externalTaskTypeNames: {
        Bobtail: 'Bobtail',
        Chassis: 'Chassis',
        'Empty delivery': 'Empty delivery',
        'Empty pickup': 'Empty pickup',
        'Loaded delivery': 'Loaded delivery',
        'Loaded pickup': 'Loaded pickup',
        'Bobtail - Bobtail': 'Bobtail - Bobtail',
        'Empty pickup - Empty delivery': 'Empty pickup - Empty delivery',
        'Loaded pickup - Loaded delivery': 'Loaded pickup - Loaded delivery',
        'Chassis - Chassis': 'Chassis - Chassis'
    },
    descending: 'Descending',
    ascending: 'Ascending',
    presetServiceTimes: {
        Bobtail: '17',
        Chassis: '25',
        'Empty delivery': '25',
        'Loaded delivery': '25',
        'Empty pickup': '40',
        'Loaded pickup': '40',
        'Bobtail - Bobtail': '34',
        'Empty pickup - Empty delivery': '65',
        'Loaded pickup - Loaded delivery': '65',
        'Chassis - Chassis': '50'
    },
    taskPriorityNames: {
        '1': 'Highest',
        '2': 'High',
        '3': 'Normal'
    },
    assignmentPosition: -1,
    defaultApiLimits: {
        assignments: 200
    },
    TIME_FORMAT_24H: 'HH:mm',
    TIME_FORMAT_12H: 'hh:mm a',
    addStops: {
        supportedExternalTaskTypes: [
            'Loaded pickup',
            'Loaded delivery',
            'Empty Pickup',
            'Empty Delivery',
            'Empty pickup',
            'Empty delivery',
            'Empty pickup - Empty delivery',
            'Bobtail - Bobtail',
            'Chassis - Chassis'
        ],
        supportedOpStopExternalTaskTypes: [
            'Weigh scales',
            'Border crossings',
            'Breakdown points',
            'Turnaround points',
            'Staging / Drop yards',
            'Wash bays',
            'Repair Bay',
            'Other'
        ]
    },
    assignmentPositionIncrement: 2,
    eldTypes: {
        None: 'N/A',
        CN: 'CN',
        GeoTab: 'GeoTab',
        Intellishift: 'Intellishift',
        Lytx: 'Lytx',
        Motive: 'Motive',
        Samsara: 'Samsara',
        Other: 'Other'
    },
    staticLinks: {
        defaultKnowledgeBaseUrl: 'https://www.wisesystems.com/knowledge'
    }
} as const;

/**
 * This type guard can be used to inform the TypeScript compiler that `value` is of type `T` when `T`
 * is used to describe members of a `constants` object.
 * @param {string} path check in constants for value under path
 * @param {*} value check under path if this value is present
 * @returns {boolean} true if value is in `constants[path]`
 */
export function isValueInConstantObject<T>(
    path: keyof typeof constants,
    value: unknown
): value is T {
    return Object.values(constants[path]).some((x) => isEqual(value, x));
}

export default constants;
