import { ServerCIEnvironment } from '~/servers/servers';

/**
 * A list of feature flags available
 *
 * These will be used as keys to locate the actual Unleash feature flag from the `envFeatureFlagMapping` constant
 *
 * When adding new feature flags, this `enum` must be updated
 */
export enum FeatureFlag {
    GLOBAL = 'global',
    CLIENT_TIMEZONE = 'client-timezone',
    ELD = 'eld',
    EV_BATTERY_REMAINING = 'ev-battery-remaining',
    INITIAL_VS_ACTUAL = 'initial-vs-actual',
    OPTIMIZATION_SUGGESTIONS = 'optimization-suggestions',
    PREDEFINED_EXTERNAL_TASK_TYPES = 'predefined-external-task-types',
    REQUEST_EXTERNAL_ID_FOR_ROUTE_EXPORT = 'request-external-id-for-route-export',
    RESET_TASKS = 'reset-tasks',
    SPLIT_TASK = 'split-task',
    SPLIT_TASK_BY_INVENTORY = 'split-task-by-inventory',
    UNARRIVE_STOPS = 'unarrive-stops',
    UPDATE_CUSTOM_MAPPING = 'update-custom-mapping',
    AURORA_AI = 'aurora-ai'
}

/**
 * Pre-defined environment array for a single flag strategy
 */
const ANY_ENVIRONMENT = [ServerCIEnvironment.ANY];

/**
 * Pre-defined environment array for a separate environment flag strategy
 */
const DEFAULT_ENVIRONMENTS = [
    ServerCIEnvironment.STAGE,
    ServerCIEnvironment.UAT,
    ServerCIEnvironment.CN_UAT,
    ServerCIEnvironment.PRODUCTION
];

/**
 * A mapping of available Unleash feature flags to support CI environments
 *
 * This map is essential for the `getEnvFeatureFlag()` utility and `useFeatureFlag()` hook
 *
 * When adding new feature flags, this `object` must be updated
 *
 * @example
 * To use feature flags for specific environments, define as follows:
 * ```
 * [featureFlag]: [
 *     ServerCIEnvironment.STAGE,
 *     ServerCIEnvironment.CN_UAT,
 *     ServerCIEnvironment.PRODUCTION
 * ]
 * ```
 *
 * Using this strategy, you will need to define separate Unleash feature flags
 * for each environment you want to support.
 *
 * Environment feature flags must conform to the following convention:
 * ```
 * {featureFlag}-{environmentSuffix}
 * ```
 *
 * Supported suffixes are defined under `FeatureFlagEnvironmentSuffix`
 *
 * @example
 * To use feature flags for the default supported environments, define as follows:
 * ```
 * * [featureFlag]: DEFAULT_ENVIRONMENTS
 * ```
 *
 * This is the same as defining:
 * ```
 * [featureFlag]: [
 *     ServerCIEnvironment.STAGE,
 *     ServerCIEnvironment.UAT,
 *     ServerCIEnvironment.CN_UAT,
 *     ServerCIEnvironment.PRODUCTION
 * ]
 * ```
 *
 * @example
 * To just use 1 feature flag for any environment, define as follows:
 * ```
 * [featureFlag]: ANY_ENVIRONMENT
 * ```
 *
 * Using this strategy, you will need to define only one Unleash feature flag,
 * and configure for any environment as a single strategy.
 *
 * @see {@link https://gitlab.com/wisesystems/wise-web-multi-client/-/feature_flags | Available Interstellar Feature Flags}
 * @see {@link https://docs.getunleash.io/reference/sdks/react | Unleash React Documentation}
 */
export const envFeatureFlagMapping: Partial<
    Record<FeatureFlag, ServerCIEnvironment[]>
> = {
    [FeatureFlag.GLOBAL]: ANY_ENVIRONMENT,
    [FeatureFlag.CLIENT_TIMEZONE]: [
        ...DEFAULT_ENVIRONMENTS,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.ELD]: [...DEFAULT_ENVIRONMENTS, ServerCIEnvironment.DEMO],
    [FeatureFlag.EV_BATTERY_REMAINING]: [
        ...DEFAULT_ENVIRONMENTS,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.INITIAL_VS_ACTUAL]: [ServerCIEnvironment.STAGE],
    [FeatureFlag.OPTIMIZATION_SUGGESTIONS]: [
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.UAT
    ],
    [FeatureFlag.PREDEFINED_EXTERNAL_TASK_TYPES]: ANY_ENVIRONMENT,
    [FeatureFlag.REQUEST_EXTERNAL_ID_FOR_ROUTE_EXPORT]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO
    ],
    [FeatureFlag.RESET_TASKS]: [ServerCIEnvironment.PRODUCTION],
    [FeatureFlag.SPLIT_TASK]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO,
        ServerCIEnvironment.UAT
    ],
    [FeatureFlag.SPLIT_TASK_BY_INVENTORY]: ANY_ENVIRONMENT,
    [FeatureFlag.UNARRIVE_STOPS]: DEFAULT_ENVIRONMENTS,
    [FeatureFlag.AURORA_AI]: [
        ServerCIEnvironment.DEVELOPMENT,
        ServerCIEnvironment.UAT
    ],
    [FeatureFlag.UPDATE_CUSTOM_MAPPING]: [
        ServerCIEnvironment.PRODUCTION,
        ServerCIEnvironment.STAGE,
        ServerCIEnvironment.DEMO,
        ServerCIEnvironment.UAT
    ]
};
