import { useFlag } from '@unleash/proxy-client-react';
import {
    FeatureFlag,
    getEnvFeatureFlag,
    isEnabledByLocalStorage,
    isEnabledOnLocalDevelopment
} from '~/utils/feature-flags-utils';

/**
 * Evaluates whether an Unleash feature flag is enabled for the current environment
 *
 * For `local development` environments:
 * + all Unleash feature flags are enabled
 * + all Unleash feature flags can be globally disabled by running `localStorage.setItem('enableFeatureFlags', 'false')`
 * + To remove the global override, run one of the following:
 *   + `localStorage.removeItem('enableFeatureFlags')`
 *   + `localStorage.setItem('enableFeatureFlags', 'true')`
 *
 * For all other environments (stage, UAT, production):
 * + each Unleash feature flag is managed using the `Interstellar` Gitlab UI
 *
 * Defining `Unleash` feature flags
 * + define new feature flag using `Interstellar` Gitlab UI
 * + For `Interstellar` to recognize the new flags, developers must edit `featureFlags.ts`:
 *   + `FeatureFlag`: update this enum with the new flag
 *   + `envFeatureFlagMapping`: update this object with an array of environments that the flag will be used
 */
export const useFeatureFlag = (flag: FeatureFlag) => {
    const featureFlag = getEnvFeatureFlag(flag);
    const isEnabledByUnleash = useFlag(featureFlag);
    const localStorageOverride = isEnabledByLocalStorage();
    const isEnabledByHostname = isEnabledOnLocalDevelopment();
    // local development override using `localStorage` by explicitly
    // looking for `localStorage.setItem('enableFeatureFlags', 'false')`
    const isDisabledByStorage =
        localStorageOverride === false && isEnabledByHostname;
    if (isDisabledByStorage) return false;

    return isEnabledByUnleash || isEnabledByHostname;
};
